<template>
  <div class="mini_game_bet">
    <sub-title>FX 4M</sub-title>
    <leisure-game-links></leisure-game-links>
    <leisure-fx-links></leisure-fx-links>
    <div class="mini_game">
      <leisure-game-ifram-view>
        <iframe id="gamefram" scrolling="no" frameborder="0"
                src="https://fxgame.com/fxgame/4min"></iframe>
      </leisure-game-ifram-view>
      <div class="game_tab">
        <leisure-bet-masker :show-masker="showMasker"></leisure-bet-masker>
        <leisure-timer-comp
                ref="ltimer"
                :leisure-game-info="leisureGameInfo"
                @timerStop="timerStop" @refreshTimer="refreshTimer">
          <span slot="title" class="text-orange"><i class="fa fa-star"></i>FX 4M </span>
        </leisure-timer-comp>

        <!--선택부분-->
        <div class="mini_bet_box">
          <div class="mg">
            <span class="t">홀/짝</span>
            <div class="mg_btn b2"
                 :class="{'active_minigame':selectedInfo.selectedAttr === leisureConst.LEISURE_ATTRIBUTE_FX_4M_ODDEVEN && selectedInfo.selectedWay === 1}"
                 @click="selected(leisureConst.LEISURE_ATTRIBUTE_FX_4M_ODDEVEN,leisureGameInfo.leisureGame.id,1, oddevenConfig.odds1, oddevenConfig,'홀')">
              <div class="r">
                <span class="n text_color01">홀</span>
                <span class="b">{{oddevenConfig.odds1}}</span>
              </div>
            </div>
            <div class="mg_btn b2"
                 :class="{'active_minigame':selectedInfo.selectedAttr === leisureConst.LEISURE_ATTRIBUTE_FX_4M_ODDEVEN && selectedInfo.selectedWay === 2}"
                 @click="selected(leisureConst.LEISURE_ATTRIBUTE_FX_4M_ODDEVEN,leisureGameInfo.leisureGame.id,2,  oddevenConfig.odds2, oddevenConfig,'짝')">
              <div class="r">
                <span class="n text_color02">짝</span>
                <span class="b">{{oddevenConfig.odds2}}</span>
              </div>
            </div>
          </div>
          <div class="mg">
            <span class="t">오버/언더</span>
            <div class="mg_btn b2"
                 :class="{'active_minigame':selectedInfo.selectedAttr === leisureConst.LEISURE_ATTRIBUTE_FX_4M_OVERUNDER && selectedInfo.selectedWay === 1}"
                 @click="selected(leisureConst.LEISURE_ATTRIBUTE_FX_4M_OVERUNDER,leisureGameInfo.leisureGame.id,1, overunderConfig.odds1, overunderConfig,'오버')">
              <div class="r">
                <span class="n text_color01">오버</span>
                <span class="b">{{overunderConfig.odds1}}</span>
              </div>
            </div>
            <div class="mg_btn b2"
                 :class="{'active_minigame':selectedInfo.selectedAttr === leisureConst.LEISURE_ATTRIBUTE_FX_4M_OVERUNDER && selectedInfo.selectedWay === 2}"
                 @click="selected(leisureConst.LEISURE_ATTRIBUTE_FX_4M_OVERUNDER,leisureGameInfo.leisureGame.id,2, overunderConfig.odds2, overunderConfig ,'언더')">
              <div class="r">
                <span class="n text_color02">언더</span>
                <span class="b">{{overunderConfig.odds2}}</span>
              </div>
            </div>
            <div class="mg">
              <span class="t">종가 매도/매수</span>
              <div class="mg_btn b2"
                   :class="{'active_minigame':selectedInfo.selectedAttr === leisureConst.LEISURE_ATTRIBUTE_FX_4M_JG_SELLBUY && selectedInfo.selectedWay === 1}"
                   @click="selected(leisureConst.LEISURE_ATTRIBUTE_FX_4M_JG_SELLBUY,leisureGameInfo.leisureGame.id,1, jonggasellbuyConfig.odds1, jonggasellbuyConfig,'종가매도')">
                <div class="r">
                  <span class="n text_color01">매도</span>
                  <span class="b">{{jonggasellbuyConfig.odds1}}</span>
                </div>
              </div>
              <div class="mg_btn b2"
                   :class="{'active_minigame':selectedInfo.selectedAttr === leisureConst.LEISURE_ATTRIBUTE_FX_4M_JG_SELLBUY && selectedInfo.selectedWay === 2}"
                   @click="selected(leisureConst.LEISURE_ATTRIBUTE_FX_4M_JG_SELLBUY,leisureGameInfo.leisureGame.id,2, jonggasellbuyConfig.odds2, jonggasellbuyConfig ,'종가매수')">
                <div class="r">
                  <span class="n text_color02">매수</span>
                  <span class="b">{{jonggasellbuyConfig.odds2}}</span>
                </div>
              </div>
            </div>
            <div class="mg">
              <span class="t">시가 매도/매수</span>
              <div class="mg_btn b2"
                   :class="{'active_minigame':selectedInfo.selectedAttr === leisureConst.LEISURE_ATTRIBUTE_FX_4M_CG_SELLBUY && selectedInfo.selectedWay === 1}"
                   @click="selected(leisureConst.LEISURE_ATTRIBUTE_FX_4M_CG_SELLBUY,leisureGameInfo.leisureGame.id,1, cegasellbuyConfig.odds1, cegasellbuyConfig,'시가매도')">
                <div class="r">
                  <span class="n text_color01">매도</span>
                  <span class="b">{{cegasellbuyConfig.odds1}}</span>
                </div>
              </div>
              <div class="mg_btn b2"
                   :class="{'active_minigame':selectedInfo.selectedAttr === leisureConst.LEISURE_ATTRIBUTE_FX_4M_CG_SELLBUY && selectedInfo.selectedWay === 2}"
                   @click="selected(leisureConst.LEISURE_ATTRIBUTE_FX_4M_CG_SELLBUY,leisureGameInfo.leisureGame.id,2, cegasellbuyConfig.odds2, cegasellbuyConfig ,'시가매수')">
                <div class="r">
                  <span class="n text_color02">매수</span>
                  <span class="b">{{cegasellbuyConfig.odds2}}</span>
                </div>
              </div>
            </div>
          </div>

          <!--파워볼 베팅카트-->
          <leisure-bet-cart-comp
                  ref="betCart" :kind="kind"
                  :selected-info="selectedInfo">
            FX4M
          </leisure-bet-cart-comp>

          <!--최근 배팅내역-->
          <leisure-lately-bet-list-comp
                  ref="latelyBetList"
                  :kind="kind"></leisure-lately-bet-list-comp>

        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import SubTitle from "../../../components/SubTitle";
  import LeisureTimerComp from "../../../components/leisuregame/LeisureTimerComp";
  import LeisureBetCartComp from "../../../components/leisuregame/LeisureBetCartComp";
  import leisureConst from "../../../common/leisureConst";
  import sportsConst from "../../../common/sportsConst";
  import {getLeisureGameAttributeConfig, getOnBetLeisureGames} from "../../../network/leisureRequest";
  import LeisureBetMasker from "../../../components/leisuregame/LeisureBetMasker";
  import {leisureGameMixin} from "../../../common/mixin";
  import LeisureLatelyBetListComp from "../../../components/leisuregame/LeisureLatelyBetListComp";
  import LeisureGameView from "../../../components/leisuregame/LeisureGameView";
  import LeisureGameLinks from "../LeisureGameLinks";
  import LeisureFxLinks from "../LeisureFxLinks";
  import LeisureGameIframView from "../LeisureGameIframView";

  export default {

    name: "SpeedKino",
    mixins: [leisureGameMixin],
    components: {
      LeisureGameIframView,
      LeisureFxLinks,
      LeisureGameLinks,
      LeisureGameView,
      LeisureLatelyBetListComp, LeisureBetMasker, LeisureBetCartComp, LeisureTimerComp, SubTitle},
    data() {
      return {
        kind: leisureConst.LEISURE_KIND_FX_4M,
      }
    },
    methods: {
      initLeisureGameArttributeConfig() {
        getLeisureGameAttributeConfig().then(res => {
          if (res.data.success) {
            this.attrConfig = res.data.data

            //배당설정
            this.oddevenConfig = this.attrConfig.find(attr => {
              return (attr.idx === leisureConst.LEISURE_ATTRIBUTE_SPEEDKINO_ODDEVEN)
            })

            this.overunderConfig = this.attrConfig.find(attr => {
              return (attr.idx === leisureConst.LEISURE_ATTRIBUTE_SPEEDKINO_OVERUNDER)
            })
            this.jonggasellbuyConfig = this.attrConfig.find(attr => {
              return (attr.idx === leisureConst.LEISURE_ATTRIBUTE_FX_4M_JG_SELLBUY)
            })
            this.cegasellbuyConfig = this.attrConfig.find(attr => {
              return (attr.idx === leisureConst.LEISURE_ATTRIBUTE_FX_4M_CG_SELLBUY)
            })
          }
        })
      }
    },
    created() {
      this.initKindConfig(this.kind)
    },
  }
</script>

<style scoped>

</style>